html {
     margin: 0;
     width: 100%;
     height: 100%;
}

body {
     margin: 0;
     width: 100%;
     height: 100%;
     overflow-x: hidden;
     -webkit-font-smoothing: antialiased;
     -moz-osx-font-smoothing: grayscale;
}

code {
     font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
          monospace;
}

#root {
     margin: 0;
     width: 100%;
     height: 100%;
}

a {
     text-decoration: none;
}

.shimmerWrapper {
     width: 0px;
     animation: fullView 0.5s forwards cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.animate {
     animation: shimmer 2s infinite linear;
     background: linear-gradient(
          to right,
          #eff1f3 4%,
          #e2e2e2 25%,
          #eff1f3 36%
     );
     background-size: 1000px 100%;
}

@keyframes shimmer {
     0% {
          background-position: -1000px 0;
     }
     100% {
          background-position: 1000px 0;
     }
}
