.root {
	width: 100%;
	height: 100%;
	display: flex;
	margin-top: -120px;
}

.searchBox {
	margin: auto;
	width: 400px;

	background-color: rgba(255, 255, 255, 1);
	padding: 12px;
}

.text {
	color: white;
}

.searchForm {
	display: flex;
	width: 100%;
}

.searchFormMobile {
	display: flex;
	flex-direction: column;
	width: calc(100% - 16px);
}

.searchButton {
	margin-left: 16px;
}

.optionsContainer {
	display: flex;
	margin-top: 8px;
}
